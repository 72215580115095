import { graphql } from "gatsby"
import Customizer from "../views/Customizer"

export const query = graphql`
  query CustomizerPage($bikeSlug: String!, $images: [String!]) {
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    bike: contentfulBicycleModel(internalTitle: { eq: $bikeSlug }) {
      contentful_id
      title
      formattedTitle
      internalTitle
      childContentfulBicycleModelLayoutJsonNode {
        internal {
          content
        }
      }
      disableWarranty
      customizerPageSeoTitle
      customizerPageSeoDescription {
        customizerPageSeoDescription
      }
      speeds {
        contentful_id
        speed
        variants {
          contentful_id
          shopifyId
          sku
          color {
            contentful_id
            internalTitle
            name
            hexCode
          }
          productListingImage {
            fluid(maxWidth: 1000, quality: 85) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          imageLayout {
            layout {
              image {
                asset {
                  fluid(maxWidth: 1000, quality: 85) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        customizerOptions {
          enabled
          colors {
            enabled
            assetId
            asset {
              contentful_id
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            variantId
            variant {
              contentful_id
              shopifyId
              title
              sku
              productListingImage {
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              imageLayout {
                layout {
                  image {
                    asset {
                      fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              color {
                contentful_id
                internalTitle
                name
                hexCode
              }
            }
          }
          accessoryCategories {
            name
            enabled
            imageLayer
            accessories {
              enabled
              assetId
              asset {
                contentful_id
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              variantId
              variant {
                contentful_id
                shopifyId
                title
                sku
                productListingImage {
                  fluid(maxWidth: 1000, quality: 85) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                color {
                  contentful_id
                  internalTitle
                  name
                  hexCode
                }
                accessory_model {
                  contentful_id
                  internalTitle
                }
              }
            }
          }
        }
      }
      sharedCustomizerOptions {
        enabled
        accessoryCategories {
          name
          enabled
          imageLayer
          accessories {
            enabled
            assetId
            asset {
              contentful_id
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            variantId
            variant {
              contentful_id
              shopifyId
              title
              sku
              productListingImage {
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              color {
                contentful_id
                internalTitle
                name
                hexCode
              }
              accessory_model {
                contentful_id
                internalTitle
              }
            }
          }
        }
      }
    }
  }
`

export default Customizer
